var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',[_c('validation-observer',{ref:"generalInformationValidation"},[_c('b-form',{ref:"formContainer",staticClass:"form-container",on:{"submit":function($event){$event.preventDefault();return _vm.validationForm($event)}}},[_c('b-col',[_c('h4',{staticClass:"mb-2"},[_vm._v("Tryb awaryjny")]),_c('b-form-group',{staticClass:"mt-2"},[_c('b-form-checkbox',{model:{value:(_vm.form.emergency_mode),callback:function ($$v) {_vm.$set(_vm.form, "emergency_mode", $$v)},expression:"form.emergency_mode"}},[_vm._v(" "+_vm._s(_vm.form.emergency_mode ? 'Tryb awaryjny włączony' : 'Tryb awaryjny wyłączony')+" ")])],1)],1),_c('b-col',[_c('h4',{staticClass:"mb-2 mt-4"},[_vm._v("Dostawa")]),_c('b-form-group',{attrs:{"label":"Próg darmowej dostawy","label-for":"v-free-delivery"}},[_c('validation-provider',{attrs:{"name":"Próg darmowej dostawy","rules":"required|positive_or_zero|decimal:2"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"v-free-delivery","type":"number","step":"0.01","min":"0","placeholder":"Koszt"},model:{value:(_vm.form.free_delivery),callback:function ($$v) {_vm.$set(_vm.form, "free_delivery", $$v)},expression:"form.free_delivery"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',[_c('h4',{staticClass:"mb-2 mt-4"},[_vm._v("Stopka")]),_c('b-form-group',{attrs:{"label":"Kategorie w stopce","label-for":"v-footer-categories"}},[_c('v-select',{attrs:{"id":"v-footer-categories","multiple":"","label":"name","deselectFromDropdown":true,"options":_vm.categories,"close-on-select":false},model:{value:(_vm.form.product_categories),callback:function ($$v) {_vm.$set(_vm.form, "product_categories", $$v)},expression:"form.product_categories"}})],1)],1),_c('b-col',[_c('h4',{staticClass:"mb-2 mt-4"},[_vm._v("Social Media")]),_c('b-form-group',{attrs:{"label":"Facebook","label-for":"v-facebook"}},[_c('validation-provider',{attrs:{"name":"Facebook","rules":"max:200"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"v-facebook","placeholder":"Facebook"},model:{value:(_vm.form.facebook_link),callback:function ($$v) {_vm.$set(_vm.form, "facebook_link", $$v)},expression:"form.facebook_link"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Instagram","label-for":"v-instagram"}},[_c('validation-provider',{attrs:{"name":"Instagram","rules":"max:200"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"v-instagram","placeholder":"Instagram"},model:{value:(_vm.form.instagram_link),callback:function ($$v) {_vm.$set(_vm.form, "instagram_link", $$v)},expression:"form.instagram_link"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Pinterest","label-for":"v-pinterest"}},[_c('validation-provider',{attrs:{"name":"Pinterest","rules":"max:200"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"v-pinterest","placeholder":"Pinterest"},model:{value:(_vm.form.pinterest_link),callback:function ($$v) {_vm.$set(_vm.form, "pinterest_link", $$v)},expression:"form.pinterest_link"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"LinkedIn","label-for":"v-linkedin"}},[_c('validation-provider',{attrs:{"name":"LinkedIn","rules":"max:200"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"v-linkedin","placeholder":"LinkedIn"},model:{value:(_vm.form.linkedin_link),callback:function ($$v) {_vm.$set(_vm.form, "linkedin_link", $$v)},expression:"form.linkedin_link"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',[_c('h4',{staticClass:"mb-2 mt-4"},[_vm._v("Kontakt")]),_c('b-form-group',{attrs:{"label":"Email serwisu","label-for":"v-service-email"}},[_c('validation-provider',{attrs:{"name":"Email serwisu","rules":"email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"v-service-email","placeholder":"Email serwisu"},model:{value:(_vm.form.service_email),callback:function ($$v) {_vm.$set(_vm.form, "service_email", $$v)},expression:"form.service_email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Numer telefonu do srewisu","label-for":"v-service-phone"}},[_c('validation-provider',{attrs:{"name":"Numer telefonu do serwisu","rules":"min:9|max:15|phone"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"v-service-phone","placeholder":"Numer telefonu do srewisu"},model:{value:(_vm.form.service_phone),callback:function ($$v) {_vm.$set(_vm.form, "service_phone", $$v)},expression:"form.service_phone"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Email sklepu","label-for":"v-shop-email"}},[_c('validation-provider',{attrs:{"name":"Email sklepu","rules":"email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"v-shop-email","placeholder":"Email sklepu"},model:{value:(_vm.form.shop_email),callback:function ($$v) {_vm.$set(_vm.form, "shop_email", $$v)},expression:"form.shop_email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Numer telefonu do sklepu","label-for":"v-shop-phone"}},[_c('validation-provider',{attrs:{"name":"Numer telefonu do sklepu","rules":"min:9|max:15|phone"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"v-shop-phone","placeholder":"Numer telefonu do sklepu"},model:{value:(_vm.form.shop_phone),callback:function ($$v) {_vm.$set(_vm.form, "shop_phone", $$v)},expression:"form.shop_phone"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Godziny otwarcia sklepu","label-for":"v-shop-hours"}},[_c('validation-provider',{attrs:{"name":"Godziny otwarcia sklepu","rules":"min:3|max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"v-shop-hours","placeholder":"Godziny otwarcia sklepu"},model:{value:(_vm.form.shop_hours),callback:function ($$v) {_vm.$set(_vm.form, "shop_hours", $$v)},expression:"form.shop_hours"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Godziny otwarcia infolinii serwisowej","label-for":"v-service-hours"}},[_c('validation-provider',{attrs:{"name":"Godziny otwarcia infolinii serwisowej","rules":"min:3|max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"v-service-hours","placeholder":"Godziny otwarcia infolinii serwisowej"},model:{value:(_vm.form.service_hours),callback:function ($$v) {_vm.$set(_vm.form, "service_hours", $$v)},expression:"form.service_hours"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Email do zapytań klienta","label-for":"v-support-email"}},[_c('validation-provider',{attrs:{"name":"Email do zapytań klienta","rules":"email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"v-support-email","placeholder":"Email do zapytań klienta"},model:{value:(_vm.form.support_email),callback:function ($$v) {_vm.$set(_vm.form, "support_email", $$v)},expression:"form.support_email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',[_c('h4',{staticClass:"mb-2 mt-4"},[_vm._v("Dane firmy")]),_c('b-form-group',{attrs:{"label":"Nazwa firmy","label-for":"v-company-name"}},[_c('validation-provider',{attrs:{"name":"Nazwa firmy","rules":"min:3|max:60"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"v-company-name","placeholder":"Nazwa firmy"},model:{value:(_vm.form.company_name),callback:function ($$v) {_vm.$set(_vm.form, "company_name", $$v)},expression:"form.company_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"NIP","label-for":"v-company-tax-code"}},[_c('validation-provider',{attrs:{"name":"NIP","rules":"min:10|max:10|numeric"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"v-company-tax-code","placeholder":"NIP"},model:{value:(_vm.form.company_nip),callback:function ($$v) {_vm.$set(_vm.form, "company_nip", $$v)},expression:"form.company_nip"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Numer konta bankowego","label-for":"v-company-account-no"}},[_c('validation-provider',{attrs:{"name":"Numer konta bankowego","rules":"min:3|max:50"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"v-company-account-no","placeholder":"Numer konta bankowego"},model:{value:(_vm.form.company_account_number),callback:function ($$v) {_vm.$set(_vm.form, "company_account_number", $$v)},expression:"form.company_account_number"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Ulica","label-for":"v-company-street"}},[_c('validation-provider',{attrs:{"name":"Ulica","rules":"min:3|max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"v-company-street","placeholder":"Ulica"},model:{value:(_vm.form.company_street),callback:function ($$v) {_vm.$set(_vm.form, "company_street", $$v)},expression:"form.company_street"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Kod pocztowy","label-for":"v-company-post-code"}},[_c('validation-provider',{attrs:{"name":"Kod pocztowy","rules":"min:2|max:10"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"v-company-post-code","placeholder":"Kod pocztowy"},model:{value:(_vm.form.company_postal_code),callback:function ($$v) {_vm.$set(_vm.form, "company_postal_code", $$v)},expression:"form.company_postal_code"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Miasto","label-for":"v-company-city"}},[_c('validation-provider',{attrs:{"name":"Miasto","rules":"min:3|max:60"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"v-company-city","placeholder":"Miasto"},model:{value:(_vm.form.company_city),callback:function ($$v) {_vm.$set(_vm.form, "company_city", $$v)},expression:"form.company_city"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"mt-5"},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mr-1",attrs:{"type":"submit","variant":"primary"}},[_vm._v(" Zapisz ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }