<template>
  <b-card>
    <validation-observer ref="generalInformationValidation">
      <b-form @submit.prevent="validationForm" class="form-container" ref="formContainer">
        <b-col>
          <h4 class="mb-2">Tryb awaryjny</h4>

          <b-form-group class="mt-2">
            <b-form-checkbox v-model="form.emergency_mode">
              {{ form.emergency_mode ? 'Tryb awaryjny włączony' : 'Tryb awaryjny wyłączony' }}
            </b-form-checkbox>
          </b-form-group>
        </b-col>

        <b-col>
          <h4 class="mb-2 mt-4">Dostawa</h4>
          <b-form-group label="Próg darmowej dostawy" label-for="v-free-delivery">
            <validation-provider
              #default="{ errors }"
              name="Próg darmowej dostawy"
              rules="required|positive_or_zero|decimal:2">
              <b-form-input
                id="v-free-delivery"
                v-model="form.free_delivery"
                type="number"
                step="0.01"
                min="0"
                placeholder="Koszt" />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>

        <b-col>
          <h4 class="mb-2 mt-4">Stopka</h4>
          <b-form-group label="Kategorie w stopce" label-for="v-footer-categories">
            <v-select
              id="v-footer-categories"
              v-model="form.product_categories"
              multiple
              label="name"
              :deselectFromDropdown="true"
              :options="categories"
              :close-on-select="false" />
          </b-form-group>
        </b-col>

        <b-col>
          <h4 class="mb-2 mt-4">Social Media</h4>
          <b-form-group label="Facebook" label-for="v-facebook">
            <validation-provider #default="{ errors }" name="Facebook" rules="max:200">
              <b-form-input id="v-facebook" placeholder="Facebook" v-model="form.facebook_link" />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group label="Instagram" label-for="v-instagram">
            <validation-provider #default="{ errors }" name="Instagram" rules="max:200">
              <b-form-input id="v-instagram" placeholder="Instagram" v-model="form.instagram_link" />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group label="Pinterest" label-for="v-pinterest">
            <validation-provider #default="{ errors }" name="Pinterest" rules="max:200">
              <b-form-input id="v-pinterest" placeholder="Pinterest" v-model="form.pinterest_link" />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group label="LinkedIn" label-for="v-linkedin">
            <validation-provider #default="{ errors }" name="LinkedIn" rules="max:200">
              <b-form-input id="v-linkedin" placeholder="LinkedIn" v-model="form.linkedin_link" />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>

        <b-col>
          <h4 class="mb-2 mt-4">Kontakt</h4>
          <b-form-group label="Email serwisu" label-for="v-service-email">
            <validation-provider #default="{ errors }" name="Email serwisu" rules="email">
              <b-form-input id="v-service-email" placeholder="Email serwisu" v-model="form.service_email" />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group label="Numer telefonu do srewisu" label-for="v-service-phone">
            <validation-provider #default="{ errors }" name="Numer telefonu do serwisu" rules="min:9|max:15|phone">
              <b-form-input id="v-service-phone" placeholder="Numer telefonu do srewisu" v-model="form.service_phone" />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group label="Email sklepu" label-for="v-shop-email">
            <validation-provider #default="{ errors }" name="Email sklepu" rules="email">
              <b-form-input id="v-shop-email" placeholder="Email sklepu" v-model="form.shop_email" />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group label="Numer telefonu do sklepu" label-for="v-shop-phone">
            <validation-provider #default="{ errors }" name="Numer telefonu do sklepu" rules="min:9|max:15|phone">
              <b-form-input id="v-shop-phone" placeholder="Numer telefonu do sklepu" v-model="form.shop_phone" />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group label="Godziny otwarcia sklepu" label-for="v-shop-hours">
            <validation-provider #default="{ errors }" name="Godziny otwarcia sklepu" rules="min:3|max:255">
              <b-form-input id="v-shop-hours" placeholder="Godziny otwarcia sklepu" v-model="form.shop_hours" />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group label="Godziny otwarcia infolinii serwisowej" label-for="v-service-hours">
            <validation-provider
              #default="{ errors }"
              name="Godziny otwarcia infolinii serwisowej"
              rules="min:3|max:255">
              <b-form-input
                id="v-service-hours"
                placeholder="Godziny otwarcia infolinii serwisowej"
                v-model="form.service_hours" />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group label="Email do zapytań klienta" label-for="v-support-email">
            <validation-provider #default="{ errors }" name="Email do zapytań klienta" rules="email">
              <b-form-input id="v-support-email" placeholder="Email do zapytań klienta" v-model="form.support_email" />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>

        <b-col>
          <h4 class="mb-2 mt-4">Dane firmy</h4>
          <b-form-group label="Nazwa firmy" label-for="v-company-name">
            <validation-provider #default="{ errors }" name="Nazwa firmy" rules="min:3|max:60">
              <b-form-input id="v-company-name" placeholder="Nazwa firmy" v-model="form.company_name" />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group label="NIP" label-for="v-company-tax-code">
            <validation-provider #default="{ errors }" name="NIP" rules="min:10|max:10|numeric">
              <b-form-input id="v-company-tax-code" placeholder="NIP" v-model="form.company_nip" />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group label="Numer konta bankowego" label-for="v-company-account-no">
            <validation-provider #default="{ errors }" name="Numer konta bankowego" rules="min:3|max:50">
              <b-form-input
                id="v-company-account-no"
                placeholder="Numer konta bankowego"
                v-model="form.company_account_number" />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group label="Ulica" label-for="v-company-street">
            <validation-provider #default="{ errors }" name="Ulica" rules="min:3|max:255">
              <b-form-input id="v-company-street" placeholder="Ulica" v-model="form.company_street" />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group label="Kod pocztowy" label-for="v-company-post-code">
            <validation-provider #default="{ errors }" name="Kod pocztowy" rules="min:2|max:10">
              <b-form-input id="v-company-post-code" placeholder="Kod pocztowy" v-model="form.company_postal_code" />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group label="Miasto" label-for="v-company-city">
            <validation-provider #default="{ errors }" name="Miasto" rules="min:3|max:60">
              <b-form-input id="v-company-city" placeholder="Miasto" v-model="form.company_city" />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>

        <b-col class="mt-5">
          <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" type="submit" variant="primary" class="mr-1">
            Zapisz
          </b-button>
        </b-col>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import LoaderMixin from '@/mixins/LoaderMixin.vue';
import NotificationMixin from '@/mixins/NotificationMixin';
import { subcategories as getCategories } from '@/http/delivery-methods';
import { show, submit } from '@/http/general-information';

export default {
  mixins: [LoaderMixin, NotificationMixin],

  data() {
    return {
      categories: [],
      autoFilteredValue: [],
      form: {
        product_categories: [],
        facebook_link: '',
        instagram_link: '',
        pinterest_link: '',
        linkedin_link: '',
        service_email: '',
        shop_email: '',
        service_phone: '',
        shop_phone: '',
        company_name: '',
        company_nip: '',
        company_street: '',
        company_postal_code: '',
        company_city: '',
        company_account_number: '',
        shop_hours: '',
        support_email: '',
        service_hours: '',
        emergency_mode: false,
        free_delivery: 0,
      },
    };
  },

  created() {
    this.fetchGeneralData();
    this.fetchCategories();
  },

  methods: {
    fetchGeneralData() {
      this.showLoader(this.$refs.formContainer);

      show()
        .then(({ data }) => {
          this.form = data.data;
        })
        .catch(() => {
          this.showErrorNotification(
            'Problem z pobraniem danych',
            'Wystąpił problem z pobraniem danych. Skontaktuj się ze swoim developerem.',
          );
        })
        .finally(() => {
          this.hideLoader();
        });
    },
    fetchCategories() {
      getCategories().then(({ data }) => {
        this.categories = data.data;
      });
    },
    validationForm() {
      this.$refs.generalInformationValidation.validate().then((success) => {
        if (success) {
          return this.onSubmit();
        }

        this.showFormValidationNotification();
      });
    },
    onSubmit() {
      this.showLoader(this.$refs.formContainer);

      submit({
        ...this.form,
        product_categories: this.form.product_categories.map((category) => category.id),
      })
        .then(() => {
          this.showSuccessNotification('Dane zostały zapisane', 'Podstawowe dane zostały pomyślnie zapisane.');
        })
        .catch(({ response: { data } }) => {
          this.showErrorNotification('Problem z zapisaniem danych', data.message);
        })
        .finally(() => {
          this.hideLoader();
        });
    },
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
